<template>
  <!-- <Game /> -->

  <div id='wrap'>
    <div class='mouth'>
      <div class='chin'></div>
      <div class='inner'>
        <div class='gum'></div>
        <div class='top'>
          <div class='tooth'></div>
          <div class='tooth'></div>
          <div class='tooth'></div>
          <div class='tooth'></div>
          <div class='tooth'></div>
        </div>
        <div class='bottom'>
          <div class='tooth'></div>
          <div class='tooth'></div>
          <div class='tooth'></div>
          <div class='tooth'></div>
          <div class='tooth'></div>
        </div>
      </div>
    </div>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
        <feComposite in="SourceGraphic" in2="goo" operator="atop" />
      </filter>
    </defs>
  </svg>


  <div class="container mb-3">
    <div class="row">

      <div class="offset-md-3 col-md-6 offset-lg-4 col-lg-4">

        <div class="mt-3">
          <!-- 1 000 GUM -->



          <!-- <div class="row mt-3">

            <div class="col">
              <div class="d-grid gap-2">
                <button class="btn btn-dark">Update</button>
              </div>
            </div>
          </div> -->
        </div>

        <div class="card text-bg-dark">
          <div class="card-body">
            <div>{{ gum.toFixed(2) }} GUM</div>
            <span class="badge text-bg-light">+3.00 GUM/hour</span>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">+6.00 GUM/hour</div>
          <div class="col text-end">84% / 5:04</div>
        </div>
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 84%"></div>
        </div>


        <div class="row mt-3">
          <div class="col">+9.00 GUM/hour</div>
          <div class="col text-end">80% / 5:04</div>
        </div>
        <div class="progress green">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 80%"></div>
        </div>


        <div class="row mt-3">
          <div class="col">+12.00 GUM/hour</div>
          <div class="col text-end">84% / 5:04</div>
        </div>
        <div class="progress bubblegum">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 84%"></div>
        </div>




        <h5 class="mt-3 mb-2">
          GUM
        </h5>

        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div>Purple x2</div>
              <span class="badge text-bg-dark">12 hour</span> <span class="badge text-bg-success">+6.00 GUM/hour</span>
              <span class="badge text-bg-light">FREE</span>
            </li>
            <li class="list-group-item">
              <div>Green x3</div>
              <span class="badge text-bg-dark">6 hour</span> <span class="badge text-bg-success">+9.00 GUM/hour</span>
            </li>
            <li class="list-group-item">
              <div>Bubble gum x4</div>
              <span class="badge text-bg-dark">3 hour</span> <span class="badge text-bg-success">+12.00 GUM/hour</span>
            </li>
          </ul>
        </div>


        <h5 class="mt-3 mb-2">
          Boost
        </h5>

        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div>Invite a friend</div>
              <span class="badge text-bg-dark">+1 GUM/hour</span>
            </li>
            <!-- <li class="list-group-item">Subscribe @gumcoin <span class="badge text-bg-dark">+8 GUM/hour</span></li> -->
            <!-- <li class="list-group-item">Mint Vachcher gumcoin +8 GUM/h</li> -->
            <!-- <li class="list-group-item">New quests coming soon</li> -->
          </ul>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import Game from '@/components/game'

import '@/assets/css/farm.css'

export default {
  name: 'HomeView',
  data() {
    return {
      gum: 0
    }
  },
  components: {
    Game
  },
  mounted() {
    window.Telegram.WebApp.setHeaderColor('#cddc39')
    window.Telegram.WebApp.setBackgroundColor('#cddc39')
    let user = window.Telegram.WebApp.initData

    alert('ddd')
    alert(JSON.stringify(user))

    setInterval(() => {
      this.gum = this.gum + 0.00083333333 + (0.00083333333 * 2)
    }, 1000)
  }
}
</script>
