<template>
    <div class="canvas" ref="click">
        <canvas ref="canvas">Gumcoin</canvas>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mode: 0,
            dropSpeed: 0.3,
            delta: 0
        }
    },
    name: 'GameView',
    components: {},
    methods: {
        startAnimation() {
            this.animation = setInterval(this.drawCanvas, 1000 / 60);
        },
        clearCanvas() {
            this.ctx.fillStyle = '#a2bac3';
            this.ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
        },
        deathAnimation() {
            if (this.splash) {
                this.ctx.drawImage(this.splash, this.width / 2 - 94, this.height / 2 - 54)
                this.splash = null
            } else {
                this.ctx.drawImage(this.scoreBoard, this.width / 2 - 118, this.height / 2 - 54);
                this.playend = true;
                this.playdata = [this.mode, this.score];
            }
            this.ctx.drawImage(this.ready, this.width / 2 - 57, this.height / 2 + 10);
            this.maxScore = Math.max(this.maxScore, this.score);
        },
        drawSky() {
            let totWidth = 0;
            while (totWidth < this.width) {
                this.ctx.drawImage(this.sky, totWidth, this.height - 221);
                totWidth += this.sky.width;
            }
        },
        drawLand() {
            let totWidth = -this.dist;
            while (totWidth < this.width) {
                this.ctx.drawImage(this.land, totWidth, this.height - 112);
                totWidth += this.land.width;
            }
            this.dist = this.dist + 2;
            let tmp = Math.floor(this.dist - this.width * 0.65) % 220;
            if (this.dist >= this.width * 0.65 && Math.abs(tmp) <= 1) {
                this.score++;
            }
        },
        drawPipe(x, y) {
            this.ctx.drawImage(this.pipe, x, 0, this.pipe.width, y);
            this.ctx.drawImage(this.pipeDown, x, y);
            this.ctx.drawImage(this.pipe, x, y + 168 + this.delta, this.pipe.width, this.height - 112);
            this.ctx.drawImage(this.pipeUp, x, y + 144 + this.delta);
            if (x < this.birdPos + 32 && x + 50 > this.birdPos && (this.birdY < y + 22 || this.birdY + 22 > y + 144 + this.delta)) {
                clearInterval(this.animation);
                this.death = 1;
            }
            else if (x + 40 < 0) {
                this.pipeSt++;
                this.pipeNumber++;
                this.pipes.push(Math.floor(Math.random() * (this.height - 300 - this.delta) + 10));
                this.pipesDir.push((Math.random() > 0.5));
            }

        },
        drawBird() {
            this.ctx.drawImage(this.bird, 0, this.birdN * 24, this.bird.width, this.bird.height / 4, this.birdPos, this.birdY, this.bird.width, this.bird.height / 4);
            this.birdF = (this.birdF + 1) % 6;
            if (this.birdF % 6 == 0) this.birdN = (this.birdN + 1) % 4;
            this.birdY -= this.birdV;
            this.birdV -= this.dropSpeed;

            if (this.birdY + 138 > this.height) {
                clearInterval(this.animation);
                this.death = 1;
            }
            this.death && this.deathAnimation()
        },
        jump() {

            window.Telegram.WebApp.HapticFeedback.impactOccurred('light')

            if (this.death) {
                this.dist = 0;
                this.birdY = (this.height - 112) / 2;
                this.birdF = 0;
                this.birdN = 0;
                this.birdV = 0;
                this.death = 0;
                this.score = 0;
                this.birdPos = this.width * 0.35;
                this.pipeSt = 0;
                this.pipeNumber = 10;
                this.pipes = [];
                this.pipesDir = [];
                for (var i = 0; i < 10; ++i) {
                    this.pipes.push(Math.floor(Math.random() * (this.height - 300 - this.delta) + 10));
                    this.pipesDir.push((Math.random() > 0.5));
                }
                this.startAnimation()
            }
            if (this.mode == 0) {
                this.birdV = 6
            } else if (mode == 1) {
                this.birdV = 6
            } else {
                this.birdV = 6
            }
        },
        loadImages() {
            let imgNumber = 9, imgComplete = 0

            const onImgLoad = () => {
                imgComplete++;
                if (imgComplete == imgNumber) {
                    this.death = 1;
                    this.dist = 0;
                    this.birdY = (this.height - 112) / 2;
                    this.birdF = 0;
                    this.birdN = 0;
                    this.birdV = 0;
                    this.birdPos = this.width * 0.35;
                    this.score = 0;
                    this.pipeSt = 0;
                    this.pipeNumber = 10;
                    this.pipes = [];
                    this.pipesDir = [];
                    for (var i = 0; i < 10; ++i) {
                        this.pipes.push(Math.floor(Math.random() * (this.height - 300 - this.delta) + 10));
                        this.pipesDir.push((Math.random() > 0.5));
                    }
                    this.drawCanvas();
                }
            }

            this.sky = new Image();
            this.sky.src = 'images/sky.png';
            this.sky.onload = onImgLoad;

            this.land = new Image();
            this.land.src = 'images/land.png';
            this.land.onload = onImgLoad;

            this.pipe = new Image();
            this.pipe.src = 'images/pipe.png';
            this.pipe.onload = onImgLoad;

            this.pipeUp = new Image();
            this.pipeUp.src = 'images/pipe-up.png';
            this.pipeUp.onload = onImgLoad;

            this.pipeDown = new Image();
            this.pipeDown.src = 'images/pipe-down.png';
            this.pipeDown.onload = onImgLoad;

            this.bird = new Image();
            this.bird.src = 'images/bird.png';
            this.bird.onload = onImgLoad;

            this.scoreBoard = new Image();
            this.scoreBoard.src = 'images/scoreboard.png';
            this.scoreBoard.onload = onImgLoad;

            this.ready = new Image();
            this.ready.src = 'images/replay.png';
            this.ready.onload = onImgLoad;

            this.splash = new Image();
            this.splash.src = 'images/splash.png';
            this.splash.onload = onImgLoad;
        },
        drawScore() {

            this.ctx.font = '20px "Press Start 2P"'
            this.ctx.lineWidth = 5
            this.ctx.strokeStyle = '#fff'
            this.ctx.fillStyle = '#000'
            let txt = "" + this.score
            this.ctx.strokeText(txt, (this.width - this.ctx.measureText(txt).width) / 2, this.height * 0.15)
            this.ctx.fillText(txt, (this.width - this.ctx.measureText(txt).width) / 2, this.height * 0.15)

        },
        drawCanvas() {
            this.clearCanvas()
            this.drawSky()

            for (var i = this.pipeSt; i < this.pipeNumber; ++i) {
                this.drawPipe(this.width - this.dist + i * 220, this.pipes[i]);
                if (this.mode == 2) {
                    if (this.pipesDir[i]) {
                        if (this.pipes[i] + 1 > this.height - 300) {
                            this.pipesDir[i] = !this.pipesDir[i];
                            this.pipes[i] -= 1;
                        }
                        else
                            this.pipes[i] += 1;
                    }
                    else {
                        if (this.pipes[i] - 1 < 10) {
                            this.pipesDir[i] = !this.pipesDir[i];
                            this.pipes[i] += 1;
                        }
                        else
                            this.pipes[i] -= 1;
                    }
                }
            }

            this.drawLand()
            // if (flashlight_switch) {
            //     drawShadow();
            // } else if (hidden_switch) {
            //     drawHidden();
            // }
            this.drawBird()
            this.drawScore();
        },
        initCanvas() {
            this.canvas = this.$refs.canvas
            this.ctx = this.canvas.getContext('2d')

            this.canvas.width = this.width = window.innerWidth
            this.canvas.height = this.height = window.innerHeight <= 700 ? window.innerHeight : 700


            this.$refs.click.addEventListener('touchstart', (e) => {
                this.jump()
                e.preventDefault()
            }, {
                passive: false
            })
            this.loadImages()
        },
        start() {

            this.initCanvas()

            window.addEventListener("resize", () => {
                this.canvas.width = this.width = window.innerWidth
                this.canvas.height = this.height = window.innerHeight <= 700 ? window.innerHeight : 700
                this.drawCanvas()
            })

        }
    },
    mounted() {
        this.start()
    }
}
</script>